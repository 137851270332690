<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <OtherWorkingAdd
      ref="workingAdd"
      :user-group-code="userGroupCode"
      :is-sidebar-active.sync="isSidebarActive"
      @add-working-hours="addWorkingHours($event)" />
    <OtherWorkingDetail
      :user-group="userGroupCode"
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-other-working="initialOtherWorking"
      :selected-item.sync="selectedItem"
      @update-working="updateWorkingHours($event)"
      @delete-item="openDeleteModal($event)" />
    <OtherWorkingFilter
      :search="filter"
      :search-wh-id.sync="filter.searchCode"
      :search-date.sync="filter.searchDate"
      :search-note.sync="filter.searchNote"
      :search-user-group.sync="filter.searchUserGroup"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Holidays"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)">
        <template #cell(date)="data">
          <span>{{ data.value | formatDateEn }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(Date)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import OtherWorkingAdd from '@/components/Others/Working/OtherWorkingAdd.vue'
import OtherWorkingDetail from '@/components/Others/Working/OtherWorkingDetail.vue'
import OtherWorkingFilter from '@/components/Others/Working/OtherWorkingFilter.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TableTop from '@/components/TableTop.vue'
import WorkingProvider from '@/resources/WorkingProvider'
import UserGroupProvider from '@/resources/GroupProvider'

const WorkingService = new WorkingProvider()
const UserGroupService = new UserGroupProvider()

export default {
  components: {
    OtherWorkingAdd,
    OtherWorkingDetail,
    OtherWorkingFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchCode: '',
        searchDate: '',
        searchNote: '',
        searchUserGroup: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'note', label: 'Note' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      selectedItem: {},
      initialOtherWorking: {},
      deleteItem: {},
      userGroupCode: []
    }
  },
  created () {
    this.getWorkingHours()
    this.getUserGroupCode()
  },
  methods: {
    async getWorkingHours () {
      try {
        const data = await WorkingService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getUserGroupCode () {
      try {
        const { data } = await UserGroupService.findAllGroupCode()
        this.userGroupCode = data
      } catch (error) {
        console.error(error)
      }
    },
    async addWorkingHours (val) {
      try {
        await WorkingService.createGroup(val)
        await this.getWorkingHours()
        this.resetFormAndToggleSideBar(this.$refs.workingAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Holidays has been added'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Holidays has been Failed ${err}`
          }
        })
      }
    },
    async updateWorkingHours (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await WorkingService.updateGroup(val.id, payload)
        this.setInitialDataAndSelectItem(data)
        await this.getWorkingHours()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Holidays has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Holidays has been Failed ${err}`
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await WorkingService.deleteGroup(val.ID)
        await this.getWorkingHours()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Holidays has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Holidays has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setInitialDataAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    setInitialDataAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialOtherWorking = { ...val }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getWorkingHours()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getWorkingHours()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
