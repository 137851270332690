<template>
  <div>
    <b-row class="align-items-end">
      <b-col
        sm="12"
        md="4"
        class="mt-1">
        <SearchTextInput
          v-model="searchNote"
          label="Note"
          placeholder="Note"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <DatePickerFilter
          v-model="searchDate"
          label="Date" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mb-1 mb-sm-0">
        <StatusDropdown
          :status-filter.sync="statusFilterValue"
          @change-status-filter="fetchFilter()" />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-end">
      <b-col
        sm="12"
        md="4"
        class="mt-1">
        <SearchTextInput
          v-model="searchUserGroup"
          label="User Group"
          placeholder="User Group"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="4"
        class="mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100"
          variant="primary"
          @click="fetchFilter()">
          <feather-icon
            icon="SearchIcon"
            class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'

export default {
  components: {
    StatusDropdown,
    SearchTextInput,
    DatePickerFilter
  },
  props: {
    search: {
      type: Object,
      default: () => (
        {
          searchCode: '',
          searchDate: '',
          searchNote: '',
          searchUserGroup: ''
        }
      )
    },
    statusFilter: {
      type: String,
      default: ''
    }
  },
  computed: {
    searchCode: {
      get () {
        return this.search.searchCode
      },
      set (val) {
        this.$emit('update:search-wh-id', val)
      }
    },
    searchNote: {
      get () {
        return this.search.searchNote
      },
      set (val) {
        this.$emit('update:search-note', val)
      }
    },
    searchDate: {
      get () {
        return this.search.searchDate
      },
      set (val) {
        this.$emit('update:search-date', val)
      }
    },
    searchUserGroup: {
      get () {
        return this.search.searchUserGroup
      },
      set (val) {
        this.$emit('update:search-user-group', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
